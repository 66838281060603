import React from "react";

import MainLayout from "../components/MainLayout/MainLayout";

const home = (props) => {
  return (
    <React.Fragment>
      <MainLayout />
    </React.Fragment>
  );
};

export default home;
