import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { WhatsApp, Phone } from "@material-ui/icons";

import { PhoneNumberWA, PhoneNumberCall } from "../../context/Context";

import TableDescription from "../TableDescription/TableDescription";
import "./Card.css";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    // textAlign: "left",
  },
});

const CardBox = (props) => {
  const classes = useStyles();

  // console.log("tes", props);

  // const formatNumber = (num) => {
  //   return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  // };

  const encodeUrlWA = (data) => {
    // var urlEncode = `Perkenalkan+diri+anda%0D%0ANama+%3A%0D%0AAlamat+%3A%0D%0A%0D%0A%2AMesin+yang+saya+akan+beli%2A%0D%0A%0D%0ANama+%3A+${data.specification[0].value}%0D%0AFiller+%3A+${data.specification[1].value}%0D%0ASealer+%3A+${data.specification[2].value}%0D%0ADimension+%3A+${data.specification[3].value}%0D%0AVolume+Max+%3A+${data.specification[4].value}%0D%0AWeight+%3A+${data.specification[5].value}%0D%0ADaya+Listrik+%3A+${data.specification[6].value}`;

    // var res = urlEncode.replace(/\s+/g, "");
    var simpleText = `Perkenalkan+diri+anda%0D%0ANama+%3A%0D%0AAlamat+%3A`;
    return simpleText;
  };

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component="img"
          alt="Contemplative Reptile"
          // height="140"
          image={`${process.env.PUBLIC_URL}/assets/images/${props.value.id}.png`}
          title="Contemplative Reptile"
        />

        <CardContent>
          <Typography gutterBottom variant="h6">
            <b>{props.value.nameMachine}</b>
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardContent>
        <TableDescription spec={props.value.specification} />
        <hr />
        {/* <Typography gutterBottom variant="subtitle2">
          <b>Rp. {formatNumber(props.value.price)}</b>
        </Typography> */}
      </CardContent>

      <CardActions className="card-action">
        <Button
          href={`tel:${PhoneNumberCall}`}
          variant="contained"
          size="large"
          startIcon={<Phone />}
        >
          Panggil
        </Button>
        <Button
          className="btn-whatsapp"
          href={`https://wa.me/${PhoneNumberWA}?text=${encodeUrlWA(
            props.value
          )}`}
          variant="contained"
          size="large"
          startIcon={<WhatsApp />}
        >
          WhatsApp
        </Button>
      </CardActions>
    </Card>
  );
};

export default CardBox;
