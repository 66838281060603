import React from "react";
import { AppBar, Toolbar, Box } from "@material-ui/core";

import "./NavBar.css";

import TitleBar from "../../components/AppBar/TitleBar/TitleBar";

const navBar = () => {
  return (
    <Box component="nav">
      <AppBar position="fixed">
        <Toolbar className="flex-container">
          <TitleBar />
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default navBar;
