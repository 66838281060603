import React from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";

import "./TableDescription.css";

const tableDescription = (props) => {
  return (
    <div>
      <Table>
        <TableBody>
          {props.spec.map((data, idx) => {
            return (
              <TableRow key={idx}>
                <th>{data.name}</th>
                <td>{data.value}</td>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};

export default tableDescription;
