import React, { Component } from "react";

import { RootContext, Data } from "./Context";

const Provider = RootContext.Provider;

const GlobalProvider = (Children) => {
  return class ParentComponent extends Component {
    constructor(props) {
      super(props);
      this.state = Data;
    }
    render() {
      return (
        <Provider value={this.state}>
          <Children {...this.props} />
        </Provider>
      );
    }
  };
};

export default GlobalProvider;
