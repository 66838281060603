import React from "react";
import Typography from "@material-ui/core/Typography";
import "./TitleBar.css";

import { PhoneNumberWA, PhoneNumberCall } from "../../../context/Context";

const titleBar = (props) => (
  <div className="container-text-bar">
    <Typography variant="h6" className="app-title">
      JAYA SUKSES PRATAMA MACHINERY
    </Typography>
    <Typography
      className="app-description"
      variant="caption"
      display="block"
      gutterBottom
    >
      Packaging Machine & Automation System
    </Typography>
    <Typography
      className="app-description"
      variant="caption"
      display="block"
      gutterBottom
    >
      Semampir RT.06 RW.03 Kec. Sedati Kab. Sidoarjo Jawatimur Kode Pos 61253 (2
      Km dari Bandara Juanda Terminal 1)
    </Typography>
    <Typography
      className="app-description"
      variant="button"
      display="block"
      gutterBottom
    >
      {PhoneNumberWA} / {PhoneNumberCall}
    </Typography>
  </div>
);

export default titleBar;
