import React, { Component } from "react";
import { BrowserRouter, Route } from "react-router-dom";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import { CssBaseline } from "@material-ui/core";

import NavBar from "../NavBar/NavBar";
import Home from "../../pages/Home";

import GlobalProvider from "../../context/Provider";
import { green, lightGreen } from "@material-ui/core/colors";

const theme = createMuiTheme({
  palette: {
    primary: green,
    secondary: lightGreen,
  },
});

class App extends Component {
  render() {
    return (
      <CssBaseline>
        <BrowserRouter>
          <MuiThemeProvider theme={theme}>
            <NavBar />
            <Route path="/" exact component={Home} />
          </MuiThemeProvider>
        </BrowserRouter>
      </CssBaseline>
    );
  }
}

export default GlobalProvider(App);
