import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Card from "../Card/Card";

import "./MainLayout.css";

import { GlobalConsumer } from "../../context/Consumer";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    paddingTop: 130,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
}));

const MainLayout = (props) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <div className={classes.root}>
        <Grid container spacing={3}>
          {props.product.map((data) => {
            return (
              <Grid key={data.id} item xs={12} sm={4} md={4} lg={4}>
                <div className={classes.paper}>
                  <Card value={data} />
                </div>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </Container>
  );
};

export default GlobalConsumer(MainLayout);
