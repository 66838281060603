import { createContext } from "react";

export const RootContext = createContext();

export const PhoneNumberWA = "+6287862651168";
export const PhoneNumberCall = "+628121711609";

export const Data = {
  product: [
    {
      id: 1,
      imageUrl: "1.png",
      nameMachine: "J 150 3SS ( Standard Three Side Seal)",
      price: 64000000,
      specification: [
        {
          name: "Type",
          value: "J 150 3SS ( 3 Side Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D 300",
        },
        {
          name: "Sealer",
          value: "Vertical 150mm, Horizontal 125mm",
        },
        {
          name: "Dimension",
          value: "670x945x1880 mm",
        },
        {
          name: "Volume Max",
          value: "100ml/50gr powder , kopi mix",
        },
        {
          name: "Weight",
          value: "± 300kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 2,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 3SS liquid ( Three Side Seal Liquid)",
      price: 70000000,
      specification: [
        {
          name: "Type",
          value: "J 150 3SS ( 3 Side Seal Liquid)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D28-50mm",
        },
        {
          name: "Sealer",
          value: "Vertikal 150mm, Horizontal 125mm",
        },
        {
          name: "Dimension",
          value: "670x945x1880 mm",
        },
        {
          name: "Volume Max",
          value: "125ml saos , sambal , bumbu pasta",
        },
        {
          name: "Weight",
          value: "± 300kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 3,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 CS ( Standard Center Seal)",
      price: 64000000,
      specification: [
        {
          name: "Type",
          value: "J 150 CS (Center Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D 300",
        },
        {
          name: "Sealer",
          value: "Vertikal 170mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x945x1880 mm",
        },
        {
          name: "Volume Max",
          value: "100ml/50gr powder , kopi , snack",
        },
        {
          name: "Weight",
          value: "± 300kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 4,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 CS liquid (Standard Center Seal Liquid)",
      price: 70000000,
      specification: [
        {
          name: "Type",
          value: "J 150 CS L ( center seal Liquid)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D28-50mm",
        },
        {
          name: "Sealer",
          value: "Vertikal 170mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x945x1880 mm",
        },
        {
          name: "Volume Max",
          value: "125ml saos ,sambal ,bumbu basah",
        },
        {
          name: "Weight",
          value: "± 350kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 5,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 3SS Jumbo ( Jumbo Three Side)",
      price: 68000000,
      specification: [
        {
          name: "Type",
          value: "J 150 3SS J ( jumbo 3 Side Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D350",
        },
        {
          name: "Sealer",
          value: "Vertical 220mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x945x1955 mm",
        },
        {
          name: "Volume Max",
          value: "500ml/250gr powder , granule",
        },
        {
          name: "Weight",
          value: "± 350kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 6,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 3SS Jumbo Liquid ( Jumbo Three Side Liquid)",
      price: 75000000,
      specification: [
        {
          name: "Type",
          value: "J 150 3SS J Liquid ( 3 Side Seal )",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D75-100mm",
        },
        {
          name: "Sealer",
          value: "Vertical 220mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x945x1955 mm",
        },
        {
          name: "Volume Max",
          value: "500ml/250gr saos, sambal ,bumbu",
        },
        {
          name: "Weight",
          value: "± 400kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 7,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 160 CS( Center Seal)",
      price: 68000000,
      specification: [
        {
          name: "Type",
          value: "J 160 CS ( Center Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D350",
        },
        {
          name: "Sealer",
          value: "Vertical 220mm,Horizontal 160mm",
        },
        {
          name: "Dimension",
          value: "670x945x1955mm",
        },
        {
          name: "Volume Max",
          value: "500ml/250gr gula , granule.",
        },
        {
          name: "Weight",
          value: "± 350kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 8,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 160 CS Liquid ( Center Seal Liquid)",
      price: 75000000,
      specification: [
        {
          name: "Type",
          value: "J 160 CS Liquid ( Center Seal)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D75-100mm",
        },
        {
          name: "Sealer",
          value: "Vertical 220mm,Horizontal 160mm",
        },
        {
          name: "Dimension",
          value: "670x945x1955mm",
        },
        {
          name: "Volume Max",
          value: "500ml/250gr saos , sambar , kecap",
        },
        {
          name: "Weight",
          value: "± 400kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 9,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 200 3SS ( Three Side Seal)",
      price: 72000000,
      specification: [
        {
          name: "Type",
          value: "J 200 3SS ( Three Side Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D350",
        },
        {
          name: "Sealer",
          value: "Vertical 250mm,Horizontal 170mm",
        },
        {
          name: "Dimension",
          value: "730x1015x2100mm",
        },
        {
          name: "Volume Max",
          value: "1000m/500gr gula , granule",
        },
        {
          name: "Weight",
          value: "± 400kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 10,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 200 3SS ( Three Side Seal Liquid)",
      price: 80000000,
      specification: [
        {
          name: "Type",
          value: "J 200 3SS L ( liquid Three Side Seal)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D100-120mm",
        },
        {
          name: "Sealer",
          value: "Vertical 250mm,Horizontal 170mm",
        },
        {
          name: "Dimension",
          value: "730x1015x2100mm",
        },
        {
          name: "Volume Max",
          value: "1000ml minyak goreng, saos",
        },
        {
          name: "Weight",
          value: "± 450kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 11,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 200 CS(Center Seal)",
      price: 72000000,
      specification: [
        {
          name: "Type",
          value: "J 200 CS ( Center Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D350",
        },
        {
          name: "Sealer",
          value: "Vertical 290mm,Horizontal 180mm",
        },
        {
          name: "Dimension",
          value: "730x1015x2100mm",
        },
        {
          name: "Volume Max",
          value: "1000m/500gr gula , kacang , jagung",
        },
        {
          name: "Weight",
          value: "± 400kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 12,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 200 CS Liquid (Center Seal Liquid)",
      price: 80000000,
      specification: [
        {
          name: "Type",
          value: "J 200 CS liquid ( Center Seal)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D100-120mm",
        },
        {
          name: "Sealer",
          value: "Vertical 270mm,Horizontal 180mm",
        },
        {
          name: "Dimension",
          value: "730x1015x2100mm",
        },
        {
          name: "Volume Max",
          value: "1000ml minyak goreng",
        },
        {
          name: "Weight",
          value: "± 450kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 13,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 4SS (Four Side Seal)",
      price: 72000000,
      specification: [
        {
          name: "Type",
          value: "J 150 4SS (Four Side Seal)",
        },
        {
          name: "Filler",
          value: "Takaran Volumetric D350",
        },
        {
          name: "Sealer",
          value: "Vertikal 185mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x1070x1880 mm",
        },
        {
          name: "Volume Max",
          value: "350ml/175gr powder",
        },
        {
          name: "Weight",
          value: "± 350kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
    {
      id: 14,
      imageUrl:
        "https://material-ui.com/static/images/cards/contemplative-reptile.jpg",
      nameMachine: "J 150 4SS (Four Side Seal Liquid)",
      price: 78000000,
      specification: [
        {
          name: "Type",
          value: "J 150 4SS L ( 4SS Liquid)",
        },
        {
          name: "Filler",
          value: "Sistem Pompa Mekanik D28-75mm",
        },
        {
          name: "Sealer",
          value: "Vertikal 185mm, Horizontal 150mm",
        },
        {
          name: "Dimension",
          value: "670x1070x1880 mm",
        },
        {
          name: "Volume Max",
          value: "400ml saos , minyak goreng",
        },
        {
          name: "Weight",
          value: "± 350kg",
        },
        {
          name: "Daya Listrik",
          value: "1200 Watt",
        },
      ],
    },
  ],
};
